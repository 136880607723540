<template>
  <div>
    <v-card
      v-if="response.status.id != '-2'"
      flat
      height="42px"
      class="d-flex align-center text-center"
      :class="[
        $vuetify.theme.dark ? 'border-dark' : 'border-light',
        getStatusColor(
          response.status.id,
          $vuetify.theme.dark ? 'dark' : 'light',
          'background'
        ),
      ]"
      @click="toggleResponseDetailDialog"
    >
      <v-card-title>
        <v-icon
          :color="
            getStatusColor(
              response.status.id,
              $vuetify.theme.dark ? 'dark' : 'light',
              'text'
            )
          "
          >{{ getStatusIcon(response.status.id) }}</v-icon
        >
      </v-card-title>
      <v-divider vertical></v-divider>
      <v-card-text>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="response.comment"
              small
              class="px-1"
              v-bind="attrs"
              v-on="on"
              light
              >mdi-comment-text</v-icon
            >
          </template>
          <span>{{ response.comment }}</span>
        </v-tooltip>
        <v-icon v-if="response.isAccepted" small class="px-1">mdi-star</v-icon>
        <v-icon v-if="response.guests.count !== 0" small class="px-1"
          >mdi-account-group</v-icon
        >

        <v-icon v-if="response.assignedTasks.length" small class="px-1"
          >mdi-clipboard-list</v-icon
        >
      </v-card-text>
    </v-card>
    <CustomDialog
      v-if="showResponseDetailDialog"
      @close="toggleResponseDetailDialog()"
      title="Rückmeldung"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content>
        <br />
        <event-response-details-card
          :event="event"
          :response="response"
          :user="user"
          outlined
        ></event-response-details-card>
        <!-- <response-editor
          v-model="response"
          :tasks="event.tasks"
        ></response-editor> -->
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import EventResponseDetailsCard from "@/components/scheduling/EventResponseDetailsCard.vue";
// import ResponseEditor from "@/components/scheduling/ResponseEditor.vue";
export default {
  name: "teamplanner-responnse-status-card",
  components: {
    CustomDialog,
    EventResponseDetailsCard,
    //  ResponseEditor
  },
  props: {
    response: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResponseDetailDialog: false,
    };
  },
  computed: {
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
  },
  methods: {
    toggleResponseDetailDialog() {
      this.showResponseDetailDialog = !this.showResponseDetailDialog;
    },

    getStatusColor(status, theme = "light", colorScheme = "background") {
      if (colorScheme === "background") {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple lighten-4" : "purple lighten-4";
          case "0":
          case "Unknown":
            return "blue-grey lighten-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green lighten-4" : "green lighten-4";
          case "2":
          case "no":
            return theme === "dark" ? "red lighten-4" : "red lighten-4";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange lighten-4" : "orange lighten-4";
          case "4":
          case "away":
            return theme === "dark" ? "blue lighten-4" : "blue lighten-4";
          case "":
          default:
            return "blue-grey lighten-2";
        }
      } else {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple darken-2" : "purple darken-2";
          case "0":
          case "Unknown":
            return "blue-grey darken-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green darken-2" : "green darken-2";
          case "2":
          case "no":
            return theme === "dark" ? "red darken-2" : "red darken-2";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange darken-2" : "orange darken-2";
          case "4":
          case "away":
            return theme === "dark" ? "blue darken-2" : "blue darken-2";
          case "":
          default:
            return "blue-grey darken-2";
        }
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "-1":
          return "mdi-cancel";
        case "0":
        case "Unknown":
          return "mdi-help-circle";
        case "1":
        case "yes":
          return "mdi-check-circle";
        case "2":
        case "no":
          return "mdi-close-circle";
        case "3":
        case "maybe":
          return "mdi-minus-circle";
        case "4":
        case "away":
          return "mdi-airplane";
        case "":
        default:
          return "mdi-alert-outline";
      }
    },
    responseIsBinding() {
      var eventResponsesAreBinding = this.event.config.responses.areBinding;
      if (eventResponsesAreBinding && this.response.status.id !== "") {
        return true;
      } else {
        return false;
      }
    },
    deadlineHasExpired() {
      if (this.event) {
        var deadline = this.getDeadline(
          this.event.config.responses.deadline,
          false
        );
        var now = new Date();

        if (now.getTime() > deadline.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    eventHasStarted() {
      if (new Date().getTime() > this.event.duration.start.timestampToDate) {
        return true;
      } else {
        return false;
      }
    },
    getDeadline(deadline, withFormat) {
      if (deadline.isCustom) {
        // custom deadline
        if (withFormat) {
          return this.timestampConverter(deadline.timestamp);
        } else {
          return deadline.timestamp.toDate();
        }
      } else {
        // global deadline

        var durationInMin = this.schedulingConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = this.data.duration.start.timestampToDate;
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        if (withFormat) {
          // return this.timestampConverter(calc);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          };
          return calc.toLocaleDateString("de-DE", options);
        } else {
          return calc;
        }
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style>
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}
</style>
